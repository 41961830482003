import moment from "moment";

export const getDayOfTheWeek = (dayNumber) => {
  switch (dayNumber) {
    case 0:
      return "Segunda-feira";
    case 1:
      return "Terça-feira";
    case 2:
      return "Quarta-feira";
    case 3:
      return "Quinta-feira";
    case 4:
      return "Sexta-feira";
    case 5:
      return "Sábado";
    case 6:
      return "Domingo";
    default:
      return "Segunda";
  }
};

export const formatDateToLocale = (date) => moment(date).format("DD/MM/YYYY");

export const formatDateToUSA = (date) =>
  moment(date, "DD/MM/YYYY").format("YYYY-MM-DD");

export const notifyError = (self, error) => {
  self.$vs.notify({
    title: "Erro",
    text: error,
    color: "danger",
    position: "bottom-center",
    icon: "error",
  });
};

export const notifySuccess = (self, message) => {
  self.$vs.notify({
    title: "Sucesso",
    text: message,
    color: "success",
    position: "bottom-center",
  });
};
